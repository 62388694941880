import {
  Button,
  Classes,
  Menu,
  MenuItem as BaseMenuItem,
  Popover,
} from '@blueprintjs/core'
import { Ecosystem } from '@mods-dev/common'
import React from 'react'
import styled from 'styled-components'
import { unknown } from '../assets'
import { useEcosystem } from '../hooks'

type Props = {
  onChange?: (ecosystem: Ecosystem) => void
}

const ButtonIcon = styled.img`
  height: 3rem;
  width: 5rem;

  object-fit: contain;
  vertical-align: middle;
`

const MenuItem = styled(BaseMenuItem)`
  align-items: center;
`

const MenuIcon = styled.img`
  height: 2rem;
  width: 3rem;

  object-fit: contain;
  vertical-align: middle;
`

const EcosystemButton = styled(Button).attrs(() => ({
  minimal: true,
  rightIcon: 'caret-down',
}))`
  // @todo hardcoded
  line-height: 4.3rem;
`

export function EcosystemSelector({ onChange }: Props) {
  const { ecosystems, data, setEcosystem } = useEcosystem()
  const { name, logo } = data || {}

  return (
    <Popover
      content={
        <Menu>
          {(Object.keys(ecosystems) as Ecosystem[]).map((key) => {
            const { logo, name } = ecosystems[key]
            return (
              <MenuItem
                className={Classes.POPOVER_DISMISS}
                key={name}
                icon={<MenuIcon src={logo} alt={name} />}
                text={name}
                onClick={() => {
                  setEcosystem(key)
                  onChange?.(key)
                }}
              />
            )
          })}
        </Menu>
      }
      placement="bottom"
    >
      <EcosystemButton>
        {name ? (
          <ButtonIcon src={logo} alt={name} />
        ) : (
          <ButtonIcon src={unknown} alt="Unknown" />
        )}
      </EcosystemButton>
    </Popover>
  )
}
