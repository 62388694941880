import { useCallback, useEffect, useRef, useState } from 'react'
import { callFunctionWithFirebase, FunctionSignatures } from '../api'
import { showError } from '../components/Toaster'
import { useFirebase } from './useApp'

export function useFunction<T extends keyof FunctionSignatures>(name: T) {
  const { app } = useFirebase()

  return useCallback(
    async function (...args: Parameters<FunctionSignatures[T]>) {
      let retVal
      try {
        retVal = await callFunctionWithFirebase(app, name, args)
      } catch (error) {
        showError(error.message)
      }
      return retVal as ReturnType<FunctionSignatures[T]>
    },
    [app, name]
  )
}

export function useFunctionResult<T extends keyof FunctionSignatures>(
  name: T,
  args?: Parameters<FunctionSignatures[T]>
) {
  const mounted = useRef<boolean>()
  const [data, setData] = useState<ReturnType<FunctionSignatures[T]>>()
  const [error, setError] = useState<Error>()
  const [fnPromise, setFnPromise] = useState<Promise<unknown>>()
  const { app } = useFirebase()

  const callFn = useCallback(() => {
    return callFunctionWithFirebase(app, name, args)
  }, [app, args, name])

  const trigger = useCallback(() => {
    const promise = callFn()
    setData(undefined)
    setFnPromise(promise)
    promise
      .then((data) => {
        if (!mounted.current) {
          return
        }

        if (data) {
          console.debug('[web] Received function results for ' + name)
          setData(data)
        }
      })
      .catch((error) => {
        if (!mounted.current) {
          return
        }

        console.debug('[web] Received function error for ' + name)
        setError(error)
      })
  }, [callFn, name])

  useEffect(() => {
    mounted.current = true

    trigger()

    return () => {
      mounted.current = false
    }
  }, [trigger])

  if (data) {
    const dataWithRefresh: ReturnType<FunctionSignatures[T]> & {
      refresh: () => void
    } = data as any
    dataWithRefresh.refresh = trigger

    return dataWithRefresh
  }

  if (error) {
    throw error
  }

  if (fnPromise) {
    throw fnPromise
  }

  return undefined
}
