import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useEcosystem, useFunction, useMod } from '../hooks'
import { ModHeader } from './ModHeader'
import { ModMetadata } from './ModMetadata'
import { ModReadme } from './ModReadme'
import { ModScores } from './ModScores'

const Columns = styled.div`
  display: flex;
`

const Column = styled.div``

const ContentColumn = styled(Column)`
  width: 45rem;
`

const MetadataColumn = styled(Column)`
  margin-left: 2rem;
  width: 20rem;
`

function ModDetailsContents() {
  console.debug('[web] Rendering ModDetails')

  const { ecosystem } = useEcosystem()
  const find = useFunction('find')
  const { mod, modData, setModData } = useMod()

  useEffect(() => {
    if (ecosystem && mod && !modData) {
      find(ecosystem, mod).then((modData) => {
        if (modData) {
          setModData(modData)
        }
      })
    }
  }, [ecosystem, find, mod, modData, setModData])

  if (!ecosystem || !mod || !modData) {
    return null
  }

  return (
    <>
      <ModHeader />
      <Columns>
        <ContentColumn>
          <ModScores />
          <ModReadme />
        </ContentColumn>
        <MetadataColumn>
          <ModMetadata />
        </MetadataColumn>
      </Columns>
    </>
  )
}

export const ModDetails = styled.div.attrs(() => ({
  children: <ModDetailsContents />,
}))`
  max-width: 67rem; // 45 + 20 + 2
  margin-left: 2rem;
`
