import { H1 } from '@blueprintjs/core'
import styled from 'styled-components'
import { Colors } from '../styles'

export const Logo = styled(H1).attrs(() => ({
  children: (
    <>
      mods<span>.dev</span>
    </>
  ),
}))`
  flex-grow: 1;
  flex-basis: 50%;

  span {
    color: ${Colors.GRAY3};
  }
`
