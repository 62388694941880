import {
  AnchorButton,
  AnchorButtonProps,
  Button,
  ButtonGroup,
  H2,
  IconName,
  MaybeElement,
  Tag as BaseTag,
  Tooltip,
} from '@blueprintjs/core'
import { differenceInSeconds, formatDistance } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isDebug } from '../api'
import {
  useAsync,
  useEcosystem,
  useFunction,
  useMod,
  useRemoteConfigFlag,
  useToggle,
} from '../hooks'
import { Score } from './Score'

// When mod data is older than 1 day, refresh when mounting this component
const MAX_AGE = 60 * 60 * 24

// Refresh on mount has a delay
const AUTO_REFRESH_DELAY = 5

const RightPart = styled.div`
  float: right;
  height: 1em;
  display: flex;
  align-items: center;
`

const Buttons = styled(ButtonGroup).attrs(() => ({
  large: true,
  minimal: true,
}))``

type AnchorButtonWithHoverIconProps = {
  hoverIcon: IconName | MaybeElement
} & AnchorButtonProps

function AnchorButtonWithHoverIcon({
  hoverIcon,
  icon,
  ...rest
}: AnchorButtonWithHoverIconProps) {
  const [hovering, toggle] = useToggle(false)

  return (
    <AnchorButton
      onMouseEnter={toggle}
      onMouseLeave={toggle}
      {...rest}
      icon={hovering ? hoverIcon : icon}
    />
  )
}

const KeywordList = styled.div``

const Keyword = styled(BaseTag).attrs(() => ({
  minimal: true,
}))`
  margin-right: 4px;
  margin-bottom: 4px;
`

export function ModHeader() {
  console.debug('[web] Rendering ModHeader')

  const { ecosystem } = useEcosystem()
  const crawl = useFunction('crawl')
  const { mod, modData, setMod, setModData } = useMod()
  const [refreshing, setRefreshing] = useState(false)
  const { async } = useAsync()

  const SHOW_KEYWORD_LIST = useRemoteConfigFlag('show_keyword_list')
  const SHOW_CLOSE_BUTTON = useRemoteConfigFlag('show_close_button')
  const SHOW_CRAWL_BUTTON = isDebug() && false

  const updatedSecondsAgo =
    refreshing || !modData || !modData.last_updated
      ? null
      : differenceInSeconds(new Date(), new Date(modData.last_updated))
  const isFresh =
    updatedSecondsAgo === null ? null : updatedSecondsAgo < MAX_AGE

  const refresh = useCallback(() => {
    if (!ecosystem || !mod || refreshing) {
      return
    }

    async(crawl(ecosystem, mod)).then((modData) => {
      setModData(modData)
      setRefreshing(false)
    })
    setRefreshing(true)
  }, [async, crawl, ecosystem, mod, refreshing, setModData])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    refreshIfNotFresh()

    return () => {
      clearTimeout(timeout)
    }

    function refreshIfNotFresh() {
      if (!modData) {
        return
      }

      if (isFresh === false) {
        timeout = setTimeout(refresh, AUTO_REFRESH_DELAY * 1000)
      }
    }
  }, [ecosystem, isFresh, mod, modData, refresh])

  if (!ecosystem || !mod || !modData) {
    return null
  }

  return (
    <section>
      <H2>
        <RightPart>
          <Buttons>
            <Tooltip
              content={
                !modData.last_updated
                  ? 'Click to refresh'
                  : !isFresh && !refreshing
                  ? 'Refreshing soon, click to refresh now'
                  : `Last updated ${formatDistance(
                      new Date(modData.last_updated),
                      new Date(),
                      { addSuffix: true }
                    )}, click to refresh now`
              }
            >
              <AnchorButtonWithHoverIcon
                onClick={refresh}
                loading={refreshing}
                icon={isFresh ? 'tick' : 'outdated'}
                hoverIcon="refresh"
                intent={isFresh ? 'success' : undefined}
              />
            </Tooltip>
            {SHOW_CRAWL_BUTTON && (
              <Button
                title="DEBUG ONLY"
                onClick={() => crawl(ecosystem, mod).then(setModData)}
                icon="refresh"
              />
            )}
            {SHOW_CLOSE_BUTTON && (
              <Button title="Close" onClick={() => setMod(null)} icon="cross" />
            )}
          </Buttons>
        </RightPart>
        <Score value={modData.scores.score} bordered />
        {modData.name}
      </H2>
      {SHOW_KEYWORD_LIST && (
        <KeywordList>
          {modData.keywords.map((keyword) => (
            <Keyword key={keyword}>{keyword}</Keyword>
          ))}
        </KeywordList>
      )}
    </section>
  )
}
