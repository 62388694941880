import { Classes, Tag } from '@blueprintjs/core'
import { formatScore, Score as ScoreValue } from '@mods-dev/common'
import styled, { css } from 'styled-components'
import { Colors } from '../styles'

const scoreColors = [
  [Colors.LIGHT_GRAY5, Colors.BLACK],
  // ['#e8f8b6', Colors.BLACK],
  // ['#c9eab1', Colors.BLACK],
  // ['#abdcaa', Colors.BLACK],
  // ['#8fcda0', Colors.BLACK],
  // ['#74bf93', Colors.BLACK],
  // ['#5cb084', Colors.WHITE],
  // ['#47a171', Colors.WHITE],
  // ['#35925b', Colors.WHITE],
  // ['#278242', Colors.WHITE],
  // ['#1d7324', Colors.WHITE],

  // https://hihayk.github.io/scale/#4/6/50/21/-59/50/100/51/F6FF67/246/255/32/white
  ['#FF814B', Colors.BLACK],
  ['#FFA14D', Colors.BLACK],
  ['#FFBE51', Colors.BLACK],
  ['#FFD855', Colors.BLACK],
  ['#FFED5A', Colors.BLACK],
  ['#FFFE60', Colors.BLACK],
  // ['#F6FF67', Colors.BLACK],
  ['#BADF52', Colors.BLACK],
  ['#7FBF3F', Colors.BLACK],
  ['#499F2E', Colors.WHITE],
  ['#208025', Colors.WHITE],
]

const scoreColorsDark = [
  [Colors.DARK_GRAY5, Colors.BLACK],
  ['#35925b1e', Colors.BLACK],
  ['#35925b37', Colors.BLACK],
  ['#35925b50', Colors.BLACK],
  ['#35925b69', Colors.BLACK],
  ['#35925b82', Colors.BLACK],
  ['#35925b9b', Colors.WHITE],
  ['#35925bb4', Colors.WHITE],
  ['#35925bcd', Colors.WHITE],
  ['#35925be6', Colors.WHITE],
  ['#35925bff', Colors.WHITE],
]

type ScoreProps = {
  value: ScoreValue | null
  bordered?: boolean
  small?: boolean
  tiny?: boolean
}

export const Score = styled(Tag)
  .withConfig({
    shouldForwardProp: (prop) => !['tiny', 'bordered', 'small'].includes(prop),
  })
  .attrs(({ value, small, tiny }: ScoreProps) => {
    const score = value !== null ? formatScore(value) : null
    return {
      title: score !== null ? score : undefined,
      round: true,
      large: small || tiny ? undefined : true,
      children: tiny ? undefined : score ? score : '-',
      className: `score-${score !== null ? score : '0'}`,
    }
  })<ScoreProps>`
  margin-right: ${({ small, tiny }) => (tiny ? '4px' : '0.5rem')};

  min-width: auto !important;
  min-height: auto !important;

  width: ${({ small, tiny }) => (tiny ? '10px' : small ? '28px' : '36px')};
  height: ${({ small, tiny }) => (tiny ? '10px' : small ? '28px' : '36px')};
  padding: 0 !important;

  font-size: ${({ small }) => (small ? '1.1rem' : '1.3rem')} !important;
  font-family: var(--font-family) !important;
  font-weight: 400 !important;

  vertical-align: middle;

  ${scoreColors.map((colors, scoreIndex) => {
    return css`
      &.score-${scoreIndex} {
        background-color: ${colors[0]} !important;
        color: ${colors[1]} !important;
      }
    `
  })}

  ${({ bordered }) =>
    bordered
      ? css`
          border: 2px solid ${Colors.LIGHT_GRAY3};
        `
      : ''}

  .${Classes.DARK} & {
    ${({ bordered }) =>
      bordered
        ? css`
            border: 2px solid ${Colors.DARK_GRAY3};
          `
        : ''}

    ${scoreColorsDark.map((colors, scoreIndex) => {
      return css`
        &.score-${scoreIndex} {
          background-color: ${colors[0]} !important;
          color: ${colors[1]} !important;
        }
      `
    })}
  }

  .${Classes.TEXT_OVERFLOW_ELLIPSIS} {
    position: relative;
    top: 1px;
    overflow: visible;
    text-decoration: none;
    text-align: center;
  }
`
