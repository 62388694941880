import { formatISO } from 'date-fns'
import { createContext, useEffect, useState } from 'react'

type Props = {
  children: React.ReactNode
}

export const TodayContext = createContext(new Date())

export function TodayProvider({ children }: Props) {
  console.debug('[web] Rendering TodayProvider')

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    function updateDateIfChanged() {
      const newDate = new Date()
      if (
        formatISO(newDate, { representation: 'date' }) !==
        formatISO(date, { representation: 'date' })
      ) {
        console.debug("it's a new day!")
        setDate(newDate)
      }
    }

    const timer = setInterval(updateDateIfChanged, 60 * 1000)

    return () => clearInterval(timer)
  }, [date])

  return <TodayContext.Provider value={date}>{children}</TodayContext.Provider>
}
