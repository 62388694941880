import { H3 } from '@blueprintjs/core'
import { Ecosystem } from '@mods-dev/common'
import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'wouter'
import { useEcosystem } from '../hooks'
import { NpmFilters } from './NpmFilters'

function FilterContents() {
  const { ecosystem } = useEcosystem()

  if (ecosystem == null || ecosystem !== Ecosystem.NPM || !ecosystem) {
    return null
  }

  return (
    <section>
      <H3>filters</H3>
      <Switch>
        <Route path={`/${Ecosystem.NPM}/:mod*`}>
          <NpmFilters />
        </Route>
        <Route>No filters available</Route>
      </Switch>
    </section>
  )
}

export const Filters = styled.div.attrs(() => ({
  children: <FilterContents />,
}))`
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
`
