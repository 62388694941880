import { Button, Classes, Icon, OL as BaseOL, Tooltip } from '@blueprintjs/core'
import { Ecosystem, MAX_RESULTS, Mod } from '@mods-dev/common'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { isDebug } from '../api'
import {
  useEcosystem,
  useFunction,
  useMod,
  useSearch,
  useSearchParams,
} from '../hooks'
import { Colors } from '../styles'
import { Score } from './Score'

const OL = styled(BaseOL)`
  list-style: none;
  padding: 0;
`

const LI = styled.li``

const A = styled.a`
  display: block;
  align-items: center;
  padding: 4px 0.5rem 4px 4px;
  border-radius: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;

  &:hover {
    background-color: ${Colors.LIGHT_GRAY3};

    .${Classes.POPOVER_TARGET} {
      display: inline;
    }
  }

  &.active,
  .${Classes.DARK} &.active,
  .${Classes.DARK} &.active:hover {
    color: white;
    background-color: var(--primary-color);
  }

  .${Classes.POPOVER_TARGET} {
    display: none;
    font-size: 12px;
    padding-left: 5px;
  }

  .${Classes.DARK} & {
    &:hover {
      background-color: ${Colors.DARK_GRAY3};
    }
  }
`

const Name = styled.span`
  text-decoration: underline;
`

const Meta = styled.span`
  float: right;
  position: relative;
  top: 9px;

  > span {
    display: block;
    color: ${Colors.LIGHT_GRAY1};
  }
`

function SearchResultsContents() {
  console.debug('[web] Rendering SearchResults')

  const { ecosystem } = useEcosystem()
  const { mod, setModData } = useMod()
  const { term } = useSearchParams()
  const { mods, currentTerm, duration } = useSearch(ecosystem, term)
  const crawl = useFunction('crawl')

  const SHOW_DEBUG = isDebug()
  const SHOW_CRAWL_BUTTON = isDebug()

  const clickHandler =
    (modData: Mod) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault()
      setModData(modData)
    }

  if (ecosystem == null || ecosystem !== Ecosystem.NPM || !ecosystem) {
    return null
  }

  if (!term && (!mods || !mods?.length)) {
    return <p>Start searching...</p>
  }

  if (!mods || !mods?.length) {
    return (
      <>
        <p className={Classes.TEXT_MUTED}>
          No results for {ecosystem}:{currentTerm} {duration}
        </p>
        {SHOW_CRAWL_BUTTON && (
          <Button
            icon="search"
            onClick={() => crawl(ecosystem, currentTerm).then(setModData)}
          >
            Crawl "{currentTerm}"
          </Button>
        )}
      </>
    )
  }

  return (
    <section>
      <Helmet>
        <title>{currentTerm}</title>
      </Helmet>
      <OL>
        {mods.map((item) => (
          <LI key={item.name}>
            <A
              className={item.name === mod ? 'active' : ''}
              rel="noindex"
              tabIndex={0}
              href={`/${ecosystem}/${item.name}`}
              onClick={clickHandler(item)}
            >
              <Score value={item.scores.score} />
              <Name>{item.name}</Name>
              {SHOW_DEBUG && (
                <Tooltip
                  content={
                    <span>
                      Score: {item.scores.score}
                      <br />
                      Search score: {item._score}
                      <br />
                      Weight: {item._score * item._score * item.scores.score}
                      <br />
                      Reason: {item._reason}
                      <br />
                      NPMS score: {item._metadata['npmsScore']}
                    </span>
                  }
                >
                  <span className={Classes.TEXT_MUTED}>debug&nbsp;</span>
                </Tooltip>
              )}
              <Meta>
                {item._reason === 'related_keywords' ? (
                  <Icon
                    htmlTitle="Related to top search result"
                    icon="random"
                    size={16}
                  />
                ) : null}
              </Meta>
            </A>
          </LI>
        ))}
      </OL>
      <p className={Classes.TEXT_MUTED}>
        {mods.length >= MAX_RESULTS ? 'First ' : ''} {mods.length} results for{' '}
        {ecosystem}:{currentTerm} {duration}
      </p>
      {SHOW_CRAWL_BUTTON && (
        <Button
          title="DEBUG ONLY"
          icon="search"
          onClick={() => crawl(ecosystem, currentTerm).then(setModData)}
        >
          Crawl "{currentTerm}"
        </Button>
      )}
    </section>
  )
}

export const SearchResults = styled.div.attrs(() => ({
  children: <SearchResultsContents />,
}))`
  max-width: 20rem;
  min-width: 20rem;
  margin-left: 2rem;
`
