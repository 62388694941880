import { Ecosystem } from '@mods-dev/common'
import debounce from 'debounce-promise'
import LRU from 'lru-cache'
import { useCallback, useEffect, useState } from 'react'
import { SearchResult } from '../api'
import { showError } from '../components/Toaster'
import { useFunction } from './useFunction'

// Cache 100 search pages for max. of 1 hour
const MAX_CACHE_SIZE = 100
const MAX_CACHE_AGE = 1000 * 60 * 60

// Wait 50ms before acting on a changed term
const DEBOUNCE_WAIT = 50

const cache = new LRU<string, SearchResult[]>({
  max: MAX_CACHE_SIZE,
  maxAge: MAX_CACHE_AGE,
})

export function useSearch(ecosystem: Ecosystem | null, term: string) {
  const search = useFunction('search')
  const [mods, setMods] = useState<SearchResult[] | null>(null)
  const [currentTerm, setCurrentTerm] = useState<string>(term)
  const [duration, setDuration] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(search, DEBOUNCE_WAIT), [search])

  useEffect(() => {
    let active = true

    load()

    return () => {
      active = false
    }

    async function load() {
      if (!ecosystem || !term) {
        return
      }

      let t0 = 0
      let t1 = 0
      let duration = 'from cache'
      const key = `${ecosystem}:${term}`
      let results = cache.get(key)

      if (typeof results === 'undefined') {
        try {
          t0 = performance.now() + DEBOUNCE_WAIT
          results = await debouncedSearch(ecosystem, term)
          cache.set(key, results)
          t1 = performance.now()
          duration = `in ${Math.round(t1 - t0)}ms`
        } catch (error: any) {
          showError(error.message)
        }
      }

      if (!active) {
        return
      }

      setMods(results || [])
      setCurrentTerm(term)
      setDuration(duration)
    }
  }, [debouncedSearch, ecosystem, term])

  return { mods, currentTerm, duration }
}
