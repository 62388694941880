import React from 'react'
import { browserIcon, nodeIcon, typescriptIcon } from '../assets'
import { useSearchParams } from '../hooks'
import { Checkbox } from './Checkbox'

const FILTERS = [
  { key: 'nodejs', label: 'Node.js', logo: nodeIcon },
  { key: 'browser', label: 'Browser', logo: browserIcon },
  { key: 'types', label: 'With types', logo: typescriptIcon },
]

export function NpmFilters() {
  const { filters, setFilters } = useSearchParams()

  return (
    <>
      {FILTERS.map((filter) => (
        <Checkbox
          key={filter.key}
          checked={filters.includes(filter.key)}
          onChange={(event) =>
            setFilters([
              ...filters.filter((e) => e !== filter.key),
              ...((event.target as HTMLInputElement).checked
                ? [filter.key]
                : []),
            ])
          }
          iconSrc={filter.logo}
        >
          {filter.label}
        </Checkbox>
      ))}
    </>
  )
}
