import { Ecosystem, Mod, Reason } from '@mods-dev/common'
import { firebasePromise } from '../context/Firebase'
import { App } from './firebase'

export type SearchResult = Mod & { _score: number; _reason: Reason }

const REGION = 'nam5'

export type FunctionSignatures = {
  search: (ecosystem: Ecosystem, term: string) => SearchResult[]
  find: (ecosystem: Ecosystem, name: string) => Mod | null
  crawl: (ecosystem: Ecosystem, name: string) => Mod
  stats: (ecosystem: Ecosystem) => any
  automlExport: (ecosystem: Ecosystem) => any
  analyzeAll: (ecosystem: Ecosystem) => any
}

export async function callFunctionWithFirebase<
  T extends keyof FunctionSignatures
>(firebase: App, name: T, args?: Parameters<FunctionSignatures[T]>) {
  const functions = firebase.functions(REGION)

  if (process.env.NODE_ENV === 'development') {
    functions.useEmulator('localhost', 5001)
  }

  const fn = functions.httpsCallable(name)
  const result = await fn(args)

  return result.data as ReturnType<FunctionSignatures[T]>
}

export async function callFunction<T extends keyof FunctionSignatures>(
  name: T,
  args?: Parameters<FunctionSignatures[T]>
) {
  const { app } = await firebasePromise

  return callFunctionWithFirebase(app, name, args)
}
