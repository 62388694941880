import { useState } from 'react'

export function useLocalStorage<T>(name: string, initialState: T) {
  const [state, setState] = useState<T>(() => {
    const localData = localStorage.getItem(name)
    return localData ? JSON.parse(localData) : initialState
  })

  return [
    state,
    (newState: T) => {
      localStorage.setItem(name, JSON.stringify(newState))
      setState(newState)
    },
  ] as const
}
