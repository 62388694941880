import { H3 } from '@blueprintjs/core'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useMod, useToggle } from '../hooks'
import { PureMarkdown as Markdown } from './Markdown'

function useRerenderTimeout(ms: number, deps: any[]) {
  const ready = useRef(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const [, toggle] = useToggle()

  useMemo(() => {
    ready.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  const isReady = useCallback(() => {
    return ready.current
  }, [])

  useEffect(() => {
    timeout.current && clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      ready.current = true
      toggle()
    }, ms)

    return () => {
      timeout.current && clearTimeout(timeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, toggle, ...deps])

  return isReady
}

export function ModReadme() {
  console.debug('[web] Rendering ModReadme')

  const { modData } = useMod()
  const isReady = useRerenderTimeout(0, [modData])

  if (!modData) {
    return null
  }

  return (
    <section>
      <H3>README.md</H3>
      {isReady() && (
        <Markdown repository={modData.repository}>{modData.readme}</Markdown>
      )}
    </section>
  )
}
