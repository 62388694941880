import styled from 'styled-components'
import { Colors } from '../styles'

const RANDOM_EMOJI = ['❤', '☕', '🍺', '🌈', '🍕', '🌳', '🌞', '🕶', '🤓']

function randomEmoji() {
  return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)]
}

export const Footer = styled.footer.attrs(() => ({
  children: (
    <>
      Mods.dev <span>{randomEmoji()}</span> Code
    </>
  ),
}))`
  color: ${Colors.GRAY2};
  padding: 2rem;

  display: flex;
  justify-content: center;

  span {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0 5px;
    color: ${Colors.RED4};
  }
`
