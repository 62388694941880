import firebase from 'firebase/app'
import 'firebase/app-check'
import 'firebase/functions'
import 'firebase/remote-config'
import { isDebug } from './config'

export type App = firebase.app.App
export type Functions = firebase.functions.Functions
export type RemoteConfig = firebase.remoteConfig.RemoteConfig

let INIT_URL = '/__/firebase/init.js'

if (process.env.NODE_ENV === 'development') {
  INIT_URL += '?useEmulator=true'
}

export function loadFirebase() {
  return new Promise<{
    app: App
    remoteConfig: firebase.remoteConfig.RemoteConfig
  }>((resolve, reject) => {
    if (typeof window.firebase !== 'undefined') {
      // Already initialized, skip
      return
    }

    // @ts-ignore
    window.firebase = firebase

    const script = document.createElement('script')
    script.setAttribute('src', INIT_URL)
    script.onload = function () {
      try {
        const app = firebase.app()
        const features = (
          [
            'auth',
            'database',
            'firestore',
            'functions',
            'messaging',
            'storage',
            'analytics',
            'remoteConfig',
            'performance',
          ] as const
        ).filter((feature) => typeof app[feature] === 'function')
        console.debug(`[web] Firebase SDK loaded with ${features.join(', ')}`)

        // App Check
        if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
          const appCheck = firebase.appCheck()
          appCheck.activate(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
          console.debug('[web] Firebase App Check activated')
        }

        // Remote Config
        const remoteConfig = app.remoteConfig()
        isDebug() && remoteConfig.setLogLevel('debug')
        remoteConfig.fetchAndActivate().then(() => {
          console.debug('[web] Firebase RemoteConfig activated')
          resolve({ app, remoteConfig })
        })
      } catch (err) {
        console.error(err)
        reject(err)
      }
    }
    document.body.appendChild(script)
  })
}
