import { useMemo } from 'react'
import { useFirebase } from './useApp'

type Params = typeof defaultConfig

const defaultConfig = {
  show_keyword_list: false,
  show_close_button: false,
  external_links_new_window: true,
}

function useRemoteConfig() {
  const { remoteConfig } = useFirebase()

  useMemo(() => {
    remoteConfig.defaultConfig = defaultConfig
  }, [remoteConfig])

  return remoteConfig
}

export function useRemoteConfigString<T extends keyof Params>(key: T) {
  return useRemoteConfig().getString(key)
}

export function useRemoteConfigFlag<T extends keyof Params>(key: T) {
  return useRemoteConfig().getBoolean(key)
}
