import { Ecosystem } from '@mods-dev/common'
import { createContext } from 'react'
import { useLocation, useRoute } from 'wouter'
import { cratesIo, npm, packagist } from '../assets'

type Props = {
  children: React.ReactNode
}

type Context = {
  ecosystem: Ecosystem | null
  ecosystems: typeof ecosystems
  data: typeof ecosystems[Ecosystem] | null
  setEcosystem: (ecosystem: Ecosystem) => void
}

export const ecosystems = {
  [Ecosystem.NPM]: {
    name: 'NPM',
    logo: npm,
  },
  [Ecosystem.Packagist]: {
    name: 'Packagist',
    logo: packagist,
  },
  [Ecosystem.CratesIo]: {
    name: 'Crates.io',
    logo: cratesIo,
  },
}

export const DEFAULT_ECOSYSTEM = Ecosystem.NPM

export const EcosystemContext = createContext<Context>({
  ecosystem: DEFAULT_ECOSYSTEM,
  ecosystems,
  data: ecosystems[DEFAULT_ECOSYSTEM],
  setEcosystem: () => {},
})

export function EcosystemProvider({ children }: Props) {
  console.debug('[web] Rendering EcosystemProvider')

  const [, params] = useRoute<{ ecosystem: Ecosystem }>('/:ecosystem')
  const [, setLocation] = useLocation()

  let ecosystem: Ecosystem | null = params?.ecosystem || DEFAULT_ECOSYSTEM
  let data

  if (ecosystems[ecosystem]) {
    data = ecosystems[ecosystem]
  } else {
    ecosystem = null
    data = null
  }

  function setEcosystem(ecosystem: Ecosystem) {
    console.debug('[web] Setting ecosystem to', ecosystem)

    setLocation(`/${ecosystem}`)
  }

  return (
    <EcosystemContext.Provider
      value={{ ecosystem, ecosystems, data, setEcosystem }}
    >
      {children}
    </EcosystemContext.Provider>
  )
}
