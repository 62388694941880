import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'wouter'
import { DEFAULT_ECOSYSTEM } from '../context'
import { Filters } from './Filters'
import { Footer } from './Footer'
import { Header } from './Header'
import { Logo } from './Logo'
import { Main } from './Main'
import { Menu } from './Menu'
import { ModDetails } from './ModDetails'
import { SearchBar } from './SearchBar'
import { SearchResults } from './SearchResults'
import { UnsupportedEcosystem } from './UnsupportedEcosystem'

export function App() {
  console.debug('[web] Rendering App')

  return (
    <Switch>
      <Route path="/">
        <Redirect to={`/${DEFAULT_ECOSYSTEM}`} replace={true} />
      </Route>
      <Route>
        <Helmet defaultTitle="mods.dev" titleTemplate="%s - mods.dev" />
        <Header>
          <Logo />
          <SearchBar />
          <Menu />
        </Header>
        <Main>
          <UnsupportedEcosystem />
          <Filters />
          <SearchResults />
          <ModDetails />
        </Main>
        <Footer />
      </Route>
    </Switch>
  )
}
