import { Mod } from '@mods-dev/common'
import { createContext, useCallback, useState } from 'react'
import { useLocation, useRoute } from 'wouter'
import { DEFAULT_ECOSYSTEM } from './Ecosystem'
import { PathParams } from './SearchParams'

type Props = {
  children: React.ReactNode
}

type Context = {
  mod: string | null
  setMod: (mod: string | null) => void
  modData: Mod | null
  setModData: (mod: Mod | null) => void
}

export const ModContext = createContext<Context>({
  mod: null,
  setMod: () => {},
  modData: null,
  setModData: () => {},
})

export function ModProvider({ children }: Props) {
  console.debug('[web] Rendering ModProvider')

  const [, params] = useRoute<PathParams>('/:ecosystem/:mod*')
  const [, setLocation] = useLocation()

  // modData
  const [modData, setModDataState] = useState<Mod | null>(null)

  // Path
  const ecosystem = params?.ecosystem || DEFAULT_ECOSYSTEM
  const mod = params?.mod || null

  const buildPath = useCallback(
    ({ mod }: { mod?: string | null } = {}) => {
      return `${ecosystem}${mod ? `/${mod}` : ''}`
    },
    [ecosystem]
  )

  const buildSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const s = searchParams.toString()

    return s ? `?${s}` : ''
  }, [])

  const setMod = useCallback(
    (mod: string | null) => {
      console.debug('[web] Setting mod to', mod)

      if (mod === null) {
        setModDataState(null)
      }

      setLocation(`/${buildPath({ mod })}${buildSearchParams()}`)
    },
    [buildPath, buildSearchParams, setLocation]
  )

  const setModData = useCallback(
    (modData: Mod | null) => {
      setModDataState(modData)
      setLocation(
        `/${buildPath({
          mod: modData ? modData.name : null,
        })}${buildSearchParams()}`
      )
    },
    [buildPath, buildSearchParams, setLocation]
  )

  return (
    <ModContext.Provider value={{ mod, setMod, modData, setModData }}>
      {children}
    </ModContext.Provider>
  )
}
