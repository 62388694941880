import { H4, Popover } from '@blueprintjs/core'
import {
  DateObject,
  ScoreCategoryName,
  ScoreValue,
  toPercentage,
  toSI,
} from '@mods-dev/common'
import { formatDistanceToNow, formatDuration } from 'date-fns'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { useMod } from '../hooks'
import { useScores } from '../hooks/useScores'
import { PureMarkdown as Markdown } from './Markdown'
import { Metadata, MetadataList, MetadataTerm, MetadataValue } from './Metadata'
import { Score } from './Score'

const Tooltip = styled(Popover).attrs(() => ({
  hoverCloseDelay: 0,
  hoverOpenDelay: 100,
  placement: 'bottom',
  interactionKind: 'hover',
}))`
  border-bottom: 1px dotted currentColor;
  cursor: help;
`

const Scores = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
`

const ScoreSub = styled.div`
  flex-basis: 33.33%;
`

function isDateObject(value: ScoreValue | null): value is DateObject {
  return typeof value === 'object' && value !== null && '_seconds' in value
}

function toDate(value: ScoreValue | null) {
  if (isDateObject(value)) {
    return new Date(value._seconds * 1000)
  }

  if (value instanceof Date) {
    return value
  }

  if (typeof value === 'string') {
    const date = new Date(value)

    return isNaN(date.getTime()) ? null : date
  }

  return null
}

const formatters = {
  raw: function (value: ScoreValue | null) {
    if (value === null) {
      return '?'
    }

    return String(value)
  },
  date: function (value: ScoreValue | null) {
    const date = toDate(value)
    if (date) {
      return formatDistanceToNow(date, { addSuffix: true })
        .replace('almost ', '')
        .replace('about ', '')
        .replace('over ', '')
    }

    console.warn('Score formatter received invalid input')
    return formatters.raw(value)
  },
  interval: function (value: ScoreValue | null) {
    if (typeof value === 'number' && isFinite(value)) {
      return formatDuration({ days: value }, { delimiter: '|' }).split('|')[0]
    }

    console.warn('Score formatter received invalid input')
    return formatters.raw(value)
  },
  numeric: function (value: ScoreValue | null) {
    if (typeof value === 'number' && isFinite(value)) {
      return toSI(value)
    }

    console.warn('Score formatter received invalid input')
    return formatters.raw(value)
  },
  percentage: function (value: ScoreValue | null) {
    if (typeof value === 'number' && isFinite(value)) {
      return toPercentage(value)
    }

    console.warn('Score formatter received invalid input')
    return formatters.raw(value)
  },
}

function format(value: ScoreValue | null, formatter: keyof typeof formatters) {
  return formatter in formatters
    ? formatters[formatter](value)
    : formatters.raw(value)
}

export function ModScores() {
  console.debug('[web] Rendering ModScores')

  const { modData } = useMod()
  const { names, descriptions, formatters } = useScores()

  if (!modData) {
    return null
  }

  return (
    <section>
      <Scores>
        {Object.values(ScoreCategoryName).map((category) => (
          <ScoreSub key={category}>
            <H4>
              <Score
                value={modData.scores.categories[category].score}
                small
                bordered
              />
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </H4>
            <MetadataList>
              {modData.scores.categories[category].data.map((data) => (
                <Fragment key={data.id}>
                  <MetadataTerm>
                    {descriptions[data.id] ? (
                      <Tooltip
                        content={<Markdown>{descriptions[data.id]!}</Markdown>}
                      >
                        {names[data.id]}
                      </Tooltip>
                    ) : (
                      names[data.id]
                    )}
                  </MetadataTerm>
                  <Metadata>
                    <MetadataValue
                      style={
                        data.score !== null ? { paddingLeft: '6px' } : undefined
                      }
                    >
                      {data.score !== null ? (
                        <Score value={data.score} tiny bordered />
                      ) : null}
                      {format(data.value, formatters[data.id])}
                    </MetadataValue>
                  </Metadata>
                </Fragment>
              ))}
            </MetadataList>
          </ScoreSub>
        ))}
      </Scores>
    </section>
  )
}
