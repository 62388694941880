import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { App, Layout } from './components'
import {
  EcosystemProvider,
  FirebaseProvider,
  ModProvider,
  SearchParamsProvider,
} from './context'
import './index.scss'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Layout>
        <FirebaseProvider>
          <SearchParamsProvider>
            <EcosystemProvider>
              <ModProvider>
                <App />
              </ModProvider>
            </EcosystemProvider>
          </SearchParamsProvider>
        </FirebaseProvider>
      </Layout>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('app')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
