import { Ecosystem } from '@mods-dev/common'
import { createContext, useCallback } from 'react'
import { useLocation, useRoute } from 'wouter'
import { DEFAULT_ECOSYSTEM } from './Ecosystem'

type Props = {
  children: React.ReactNode
}

type Filters = string[]

export type PathParams = {
  ecosystem: Ecosystem
  mod: string
}

type Context = {
  term: string
  setTerm: (term: string) => void
  filters: Filters
  setFilters: (filters: Filters) => void
}

const FILTER_SEPARATOR = ','

export function searchTofilters(filters: string): Filters {
  try {
    return filters.split(FILTER_SEPARATOR)
  } catch (err) {
    return []
  }
}

export function filtersToSearch(filters: Filters) {
  return filters.filter((e) => e).join(FILTER_SEPARATOR)
}

export const SearchParamsContext = createContext<Context>({
  term: '',
  setTerm: () => {},
  filters: [],
  setFilters: () => {},
})

export function SearchParamsProvider({ children }: Props) {
  console.debug('[web] Rendering SearchParamsProvider')

  const [, params] = useRoute<PathParams>('/:ecosystem/:mod*')
  const [, setLocation] = useLocation()
  const searchParams = new URLSearchParams(window.location.search)

  // Path
  const ecosystem = params?.ecosystem || DEFAULT_ECOSYSTEM
  const mod = params?.mod || null

  // Search
  const term = searchParams.get('q') || ''
  const filters = searchTofilters(searchParams.get('f') || '')

  const buildPath = useCallback(() => {
    return `${ecosystem}${mod ? `/${mod}` : ''}`
  }, [ecosystem, mod])

  const buildSearchParams = useCallback(
    ({
      newTerm,
      newFilters,
    }: { newTerm?: string; newFilters?: Filters } = {}) => {
      const q = typeof newTerm !== 'undefined' ? newTerm : term
      const f = filtersToSearch(newFilters || filters)
      const p = new URLSearchParams()
      let s

      q && p.set('q', q)
      f && p.set('f', f)
      s = p.toString()

      return s ? `?${s}` : ''
    },
    [filters, term]
  )

  const setFilters = useCallback(
    (newFilters: Filters) => {
      console.debug('[web] Setting filters to', newFilters)

      setLocation(`/${buildPath()}${buildSearchParams({ newFilters })}`, {
        replace: true,
      })
    },
    [buildPath, buildSearchParams, setLocation]
  )

  const setTerm = useCallback(
    (newTerm: string) => {
      console.debug('[web] Setting search term to', newTerm)

      setLocation(`/${buildPath()}${buildSearchParams({ newTerm })}`, {
        replace: true,
      })
    },
    [buildPath, buildSearchParams, setLocation]
  )

  return (
    <SearchParamsContext.Provider
      value={{ term, setTerm, filters, setFilters }}
    >
      {children}
    </SearchParamsContext.Provider>
  )
}
