import { createContext, useEffect, useState } from 'react'
import { App, loadFirebase, RemoteConfig } from '../api'
import { Loading } from '../components'

type Props = {
  children: React.ReactNode
}

type Context = {
  app: App
  remoteConfig: RemoteConfig
}

export const firebasePromise = loadFirebase()

export const FirebaseContext = createContext<Context>(null as any)

export function FirebaseProvider({ children }: Props) {
  console.debug('[web] Rendering FirebaseProvider')

  const [value, setValue] = useState<Context>()

  useEffect(() => {
    firebasePromise.then(setValue)
  }, [])

  if (!value) {
    return <Loading />
  }

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}
