import { Colors } from '@blueprintjs/core'
import styled from 'styled-components'
import { useDarkMode } from '../hooks'

export const Header = styled.header.attrs(() => ({
  darkMode: useDarkMode().value,
}))`
  height: 9rem;
  padding: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${Colors.WHITE};

  .bp4-dark & {
    background-color: ${Colors.DARK_GRAY3};
  }
`
