import { Colors as BaseColors } from '@blueprintjs/core'

export const Colors = {
  ...BaseColors,

  GRAY3: '#a5a5a5',

  LIGHT_GRAY1: '#bbbbbb',
  LIGHT_GRAY2: '#c5c5c5',
  LIGHT_GRAY3: '#dbdbdb',
  LIGHT_GRAY4: '#ebebeb',
  LIGHT_GRAY5: '#f1f1f1',

  BLUE1: '#470a95',
  BLUE2: '#6917d4',
  BLUE3: '#8539e8',
  BLUE4: '#9a5deb',
  BLUE5: '#ad7ee9',
}
