import { OverlayToaster, Position } from '@blueprintjs/core'

export const Toaster = OverlayToaster.create({
  position: Position.BOTTOM,
})

export function showError(message: string) {
  Toaster.show({
    intent: 'danger',
    icon: 'warning-sign',
    message:
      message.toUpperCase() === 'INTERNAL'
        ? 'An error occured, please check the console for details'
        : message,
    timeout: 10000,
  })
}

export function showMessage(message: string) {
  Toaster.show({
    intent: 'success',
    icon: 'info-sign',
    message,
    timeout: 10000,
  })
}
