/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Menu as BaseMenu, MenuItem, Popover } from '@blueprintjs/core'
import styled from 'styled-components'
import { isDebug } from '../api'
import { useEcosystem, useFunction } from '../hooks'
import { showMessage } from './Toaster'

const BottomMenu = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
`

function MenuContents() {
  const DEBUG = isDebug()
  const { ecosystem } = useEcosystem()
  const additionalFunctions: any = {
    stats: useFunction('stats'),
    automlExport: useFunction('automlExport'),
    analyzeAll: useFunction('analyzeAll'),
  }

  return (
    <>
      <a
        tabIndex={0}
        href="https://github.com/mods-dev/mods-dev"
        target="_blank"
        rel="noreferrer"
      >
        about
      </a>
      {DEBUG && ecosystem && (
        <BottomMenu>
          <Popover
            content={
              <BaseMenu>
                {Object.keys(additionalFunctions).map((name) => (
                  <MenuItem
                    key={name}
                    onClick={() =>
                      additionalFunctions[name](ecosystem).then((data: any) => {
                        if (data) {
                          showMessage(
                            'Function completed, please check console for output'
                          )
                          console.log(data)
                        }
                      })
                    }
                    text={name}
                  />
                ))}
              </BaseMenu>
            }
            placement="left-end"
          >
            <Button icon="function" minimal large title="DEBUG ONLY" />
          </Popover>
        </BottomMenu>
      )}
    </>
  )
}

export const Menu = styled.div.attrs(() => ({
  children: <MenuContents />,
}))`
  flex-grow: 1;
  flex-basis: 50%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`
