import analyzerReadmeUrl from '@mods-dev/analyzer/README.md'
import { ScoreId } from '@mods-dev/common'
import { Content, Parent } from 'mdast'
import { useEffect, useState } from 'react'
import remarkParser from 'remark-parse'
import remarkStringify from 'remark-stringify'
import unified from 'unified'
import { showError } from '../components/Toaster'

type Descriptions = {
  [key in ScoreId]?: string
}

type Formatters = {
  [key in ScoreId]: 'raw' | 'date' | 'interval' | 'numeric' | 'percentage'
}

type Names = {
  [key in ScoreId]: string
}

const formatters: Formatters = {
  open_cves: 'raw',
  open_issues: 'raw',
  issue_age: 'raw',
  stars: 'numeric',
  recent_downloads: 'numeric',
  downloads_trend: 'raw',
  dependants: 'raw',
  last_commit: 'date',
  last_release: 'date',
  contributors: 'raw',
  coverage: 'percentage',
}

const names: Names = {
  open_cves: 'Open CVEs',
  open_issues: 'Open issues',
  issue_age: 'Issue age',
  stars: 'Stars',
  recent_downloads: 'Downloads',
  downloads_trend: 'Trend',
  dependants: 'Dependants',
  last_commit: 'Last commit',
  last_release: 'Last release',
  contributors: 'Contributors',
  coverage: 'Test coverage',
}

function extractScoreDescriptions(markdown: string) {
  const parsed = unified().use(remarkParser).parse(markdown) as Parent
  const stringifier = unified().use(remarkStringify)

  let descriptions: Descriptions = {}

  let matching = false
  let currentId: ScoreId | null = null
  let currentNodes: Content[] = []

  function addDescription(id: ScoreId, nodes: Content[]) {
    descriptions[id] = stringifier.stringify({
      type: 'root',
      children: nodes,
    })
  }

  parsed.children.forEach((node) => {
    // Start matching at '# Scoring algorithms'
    if (
      node.type === 'heading' &&
      node.depth === 1 &&
      node.children[0] &&
      node.children[0].value === 'Scoring algorithms'
    ) {
      matching = true

      // End matching at next '#' heading
    } else if (matching && node.type === 'heading' && node.depth === 1) {
      matching = false

      // Found id at '###' heading
    } else if (
      matching &&
      node.type === 'heading' &&
      node.depth === 3 &&
      node.children[0]
    ) {
      if (currentId) {
        addDescription(currentId, currentNodes)
      }
      currentId = String(node.children[0].value) as ScoreId
      currentNodes = []

      // Reset id at '##' heading
    } else if (
      matching &&
      currentId &&
      node.type === 'heading' &&
      node.depth === 2
    ) {
      addDescription(currentId, currentNodes)
      currentId = null
      currentNodes = []

      // Content
    } else if (matching && currentId) {
      currentNodes.push(node)
    }
  })

  // Remainder
  if (currentId) {
    addDescription(currentId, currentNodes)
  }

  return descriptions
}

export function useScores() {
  const [descriptions, setDescriptions] = useState<Descriptions>({})

  useEffect(() => {
    let active = true

    load()

    return () => {
      active = false
    }

    async function load() {
      if (!analyzerReadmeUrl) {
        return
      }

      let markdown

      try {
        const res = await fetch(analyzerReadmeUrl)
        markdown = await res.text()
      } catch (error: any) {
        showError(error.message)
      }

      if (!active || !markdown) {
        return
      }

      setDescriptions(extractScoreDescriptions(markdown))
    }
  }, [])

  return { names, descriptions, formatters }
}
