import { Checkbox as BaseCheckbox, Classes } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { Colors } from '../styles'

type Props = React.ComponentProps<typeof BaseCheckbox> & {
  children: string
  iconSrc?: string
}

const Icon = styled.img`
  transition: all 0.1s ease-in;

  height: 1.5rem;
  width: 1.5rem;

  object-fit: contain;
  image-rendering: auto;
`

const StyledCheckbox = styled(BaseCheckbox)`
  display: flex;

  align-items: center;
  line-height: 2.5rem;

  padding: 4px 10px !important;
  margin-left: -10px;
  margin-right: -10px;

  border-radius: 4px;

  ${Icon} {
    filter: grayscale(100%);
    opacity: 0.3;
  }

  .${Classes.CONTROL_INDICATOR} {
    height: 1.3em;
    width: 1.3em;

    margin-left: initial !important;

    background-image: none;
    border: 2px solid ${Colors.BLUE3};
    box-shadow: inset 0 0 0 2px ${Colors.LIGHT_GRAY5};
  }

  /* &.${Classes.CHECKBOX} .${Classes.CONTROL_INDICATOR} {
    border-radius: 50%;
  } */

  input:checked {
    ~ .${Classes.CONTROL_INDICATOR} {
      background-color: ${Colors.BLUE3};
      box-shadow: inset 0 0 0 2px ${Colors.LIGHT_GRAY5};
    }

    ~ ${Icon} {
      filter: none;
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${Colors.LIGHT_GRAY3};

    input:checked ~ .${Classes.CONTROL_INDICATOR} {
      background-color: ${Colors.BLUE3};
      box-shadow: inset 0 0 0 2px ${Colors.LIGHT_GRAY5};
    }
  }

  input:focus ~ .${Classes.CONTROL_INDICATOR} {
    outline-offset: 4px;
  }

  input:not(:disabled):active:checked ~ .${Classes.CONTROL_INDICATOR} {
    background-color: ${Colors.LIGHT_GRAY5};
  }

  &:hover {
    .${Classes.CONTROL_INDICATOR} {
      background-color: ${Colors.LIGHT_GRAY5};
    }

    ${Icon} {
      opacity: 0.6;
    }
  }

  .${Classes.DARK} & {
    .${Classes.CONTROL_INDICATOR} {
      border: 2px solid ${Colors.BLUE4};
      box-shadow: inset 0 0 0 2px ${Colors.DARK_GRAY5};
    }

    input:checked {
      ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${Colors.BLUE4};
        box-shadow: inset 0 0 0 2px ${Colors.DARK_GRAY5};
      }
    }

    &:hover {
      background-color: ${Colors.DARK_GRAY3};

      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${Colors.BLUE4};
        box-shadow: inset 0 0 0 2px ${Colors.DARK_GRAY5};
      }
    }

    input:not(:disabled):active:checked ~ .${Classes.CONTROL_INDICATOR} {
      background-color: ${Colors.DARK_GRAY5};
    }

    &:hover {
      .${Classes.CONTROL_INDICATOR} {
        background-color: ${Colors.DARK_GRAY5};
      }
    }
  }
`

const Label = styled.span`
  flex-grow: 1;
`

export function Checkbox({ iconSrc, children, ...rest }: Props) {
  return (
    <StyledCheckbox {...rest}>
      <Label>{children}</Label>
      {iconSrc ? <Icon src={iconSrc} alt={children} /> : null}
    </StyledCheckbox>
  )
}
