import { Tag as BaseTag } from '@blueprintjs/core'
import React from 'react'
import styled, { css } from 'styled-components'

export const MetadataValue = styled(BaseTag).attrs<{ avatar?: string | null }>(
  ({ icon, avatar, children }) => ({
    minimal: true,
    icon: avatar ? <Avatar src={avatar} alt={String(children)} /> : icon,
    avatar,
  })
)<{ avatar?: string | null }>`
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  ${(props) =>
    props.avatar
      ? css`
          padding-left: 2rem !important;
        `
      : ''}
`

export const Avatar = styled.img`
  position: absolute;
  top: 2px;
  left: 2px;
  width: auto;
  height: 20px;
  border-radius: 4px;
`

export const MetadataList = styled.dl``

export const MetadataTerm = styled.dt`
  display: inline-block;
  width: 40%;
  margin: 0 0 1rem 0;

  white-space: nowrap;
  vertical-align: middle;
  padding-right: 1rem;
`

export const Metadata = styled.dd`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;

  width: 60%;
  margin: 0 0 1rem 0;

  vertical-align: middle;
`
