import { Classes, InputGroup as BaseInputGroup } from '@blueprintjs/core'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useSearchParams } from '../hooks'
import { EcosystemSelector } from './EcosystemSelector'

const InputGroup = styled(BaseInputGroup)`
  width: calc(100% - 20rem);

  .${Classes.INPUT} {
    height: 5rem !important;
    font-family: var(--heading-font-family) !important;
    font-size: 2rem !important;

    border: 2px solid var(--primary-color);
    border-radius: 4px;

    box-shadow: none;

    &:focus {
      box-shadow: 0 0 0 6px rgba(var(--primary-color-components), 0.3);
    }
  }
`

export function SearchBar() {
  const { term, setTerm } = useSearchParams()
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <InputGroup
      large
      autoFocus
      placeholder="search modules..."
      type="search"
      leftElement={
        <EcosystemSelector onChange={() => inputRef.current?.focus()} />
      }
      value={term}
      onChange={(event) => setTerm(event.target.value)}
      inputRef={inputRef}
    />
  )
}
