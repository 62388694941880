import { Button, ButtonGroup, Classes, Colors } from '@blueprintjs/core'
import { Mod, RepositoryProvider } from '@mods-dev/common'
import React from 'react'
import styled from 'styled-components'
import {
  browserIcon,
  definitelytypedIcon,
  github,
  nodeIcon,
  npm,
  typescriptIcon,
} from '../assets'
import {
  useEcosystem,
  useLocalStorage,
  useMod,
  useRemoteConfigFlag,
} from '../hooks'
import { PureMarkdown as Markdown } from './Markdown'
import { Metadata, MetadataList, MetadataTerm, MetadataValue } from './Metadata'

const PackageManagerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PackageManagerButtons = styled(ButtonGroup).attrs(() => ({
  minimal: true,
}))`
  button {
    color: ${Colors.GRAY2} !important;

    .${Classes.DARK} & {
      color: ${Colors.GRAY2} !important;
    }
  }
`

const Link = styled.a`
  display: block;
  margin: 0.5rem 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkImage = styled.img`
  width: 2rem;
  height: 2rem;

  object-fit: contain;

  vertical-align: middle;
  padding-right: 0.5rem;
`

function installInstructionsAsMarkdown(
  packageManager: 'npm' | 'yarn',
  modData: Mod
) {
  switch (packageManager) {
    case 'npm':
      return `\`\`\`text
npm install ${modData.name}
\`\`\``

    case 'yarn':
      return `\`\`\`text
yarn add ${modData.name}
\`\`\``
  }
}

export function ModMetadata() {
  console.debug('[web] Rendering ModMetadata')

  const { ecosystem } = useEcosystem()
  const { mod, modData } = useMod()
  const [packageManager, setPackageManager] = useLocalStorage<'npm' | 'yarn'>(
    'packageManager',
    'npm'
  )
  const EXTERNAL_LINKS_NEW_WINDOW = useRemoteConfigFlag(
    'external_links_new_window'
  )

  if (!ecosystem || !mod || !modData) {
    return null
  }

  return (
    <section>
      <section>
        <PackageManagerContainer>
          Install instructions:
          <PackageManagerButtons>
            <Button
              small
              onClick={() => setPackageManager('npm')}
              style={{
                textDecoration:
                  packageManager === 'npm' ? 'underline' : undefined,
              }}
            >
              npm
            </Button>
            <Button
              small
              onClick={() => setPackageManager('yarn')}
              className={Classes.TEXT_MUTED}
              style={{
                textDecoration:
                  packageManager === 'yarn' ? 'underline' : undefined,
              }}
            >
              yarn
            </Button>
          </PackageManagerButtons>
        </PackageManagerContainer>
        <Markdown>
          {installInstructionsAsMarkdown(packageManager, modData)}
        </Markdown>
      </section>
      <MetadataList>
        <MetadataTerm>Version</MetadataTerm>
        <Metadata>
          <MetadataValue>v{modData.latest_version}</MetadataValue>
        </Metadata>

        <MetadataTerm>Targets</MetadataTerm>
        <Metadata>
          {/* <MetadataValue icon="help">Unknown</MetadataValue> */}
          <MetadataValue avatar={browserIcon}>Browser</MetadataValue>
          <MetadataValue avatar={nodeIcon}>Node.js</MetadataValue>
        </Metadata>

        <MetadataTerm>Types</MetadataTerm>
        <Metadata>
          {/* <MetadataValue icon="disable">Untyped</MetadataValue> */}
          <MetadataValue avatar={typescriptIcon}>TypeScript</MetadataValue>
          <MetadataValue avatar={definitelytypedIcon}>
            DefinitelyTyped
          </MetadataValue>
        </Metadata>

        {modData.owner ? (
          <>
            <MetadataTerm>Author</MetadataTerm>
            <Metadata>
              {modData.owner.web_url ? (
                <a
                  href={modData.owner.web_url}
                  target={EXTERNAL_LINKS_NEW_WINDOW ? '_blank' : undefined}
                  rel="noreferrer"
                >
                  <MetadataValue interactive avatar={modData.owner.avatar_url}>
                    {modData.owner.name}
                  </MetadataValue>
                </a>
              ) : (
                <MetadataValue avatar={modData.owner.avatar_url}>
                  {modData.owner.name}
                </MetadataValue>
              )}
            </Metadata>
          </>
        ) : null}
      </MetadataList>
      <section>
        <p>External links:</p>
        {modData.repository &&
        modData.repository.provider === RepositoryProvider.GitHub ? (
          <Link
            href={modData.repository.web_url}
            target={EXTERNAL_LINKS_NEW_WINDOW ? '_blank' : undefined}
            rel="noreferrer"
          >
            <LinkImage src={github} alt="GitHub logo" />
            github.com/{modData.repository.owner}/{modData.repository.name}
          </Link>
        ) : null}
        <Link
          href={`https://www.npmjs.com/package/${modData.name}`}
          target={EXTERNAL_LINKS_NEW_WINDOW ? '_blank' : undefined}
          rel="noreferrer"
        >
          <LinkImage src={npm} alt="NPM logo" />
          npmjs.com/package/{modData.name}
        </Link>
      </section>
    </section>
  )
}
