import React from 'react'
import styled from 'styled-components'
import { useDarkMode } from '../hooks'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

export function Layout({ children }: { children: React.ReactNode }) {
  console.debug('[web] Rendering Layout')

  useDarkMode(true)

  return <Container>{children}</Container>
}
