import { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

// https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/

// Will return `true` if the component is mounted
function useMountedRef() {
  const mountedRef = useRef(false)
  const isMounted = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return isMounted
}

export function useAsync() {
  const isMounted = useMountedRef()

  const async = useCallback(
    <T>(promise: Promise<T>) => {
      return new Promise<T>((resolve) => {
        promise.then((value) => {
          if (isMounted()) {
            // See https://stackoverflow.com/a/48610973
            ReactDOM.unstable_batchedUpdates(() => {
              resolve(value)
            })
          }
        })
      })
    },
    [isMounted]
  )

  return { async, isMounted }
}
