import { Callout as BaseCallout, CalloutProps } from '@blueprintjs/core'
import { Ecosystem } from '@mods-dev/common'
import React from 'react'
import styled from 'styled-components'
import { useEcosystem } from '../hooks'

const Callout = styled.div.attrs((props: CalloutProps) => ({
  children: <BaseCallout {...props} />,
}))<CalloutProps & { children: any }>`
  min-width: 40rem;
`

export function UnsupportedEcosystem() {
  console.debug('[web] Rendering UnsupportedEcosystem')

  const { ecosystem, ecosystems } = useEcosystem()

  if (ecosystem == null) {
    return (
      <Callout intent="primary" icon="help" title={`Unknown ecosystem`}>
        Contributions are welcome, take a look at{' '}
        <a href="https://github.com/mods-dev/mods-dev">our GitHub repository</a>
        .
      </Callout>
    )
  }

  if (ecosystem && ecosystem !== Ecosystem.NPM) {
    return (
      <Callout
        intent="primary"
        icon="code"
        title={`Support for ${ecosystems[ecosystem].name} is coming soon!`}
      >
        Contributions are welcome, take a look at{' '}
        <a href="https://github.com/mods-dev/mods-dev">our GitHub repository</a>
        .
      </Callout>
    )
  }

  if (!ecosystem) {
    return <p>Select ecosystem</p>
  }

  return null
}
