import { Classes, HTMLTable } from '@blueprintjs/core'
import { Mod, RepositoryProvider } from '@mods-dev/common'
import defaultSchema from 'hast-util-sanitize/lib/github.json'
import React, { useMemo } from 'react'
import BaseMarkdown, { TransformOptions } from 'react-markdown'
// @ts-ignore
import rehypeHighlight from 'rehype-highlight'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
// @ts-ignore
import rehypeUrls from 'rehype-urls'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
// @ts-ignore
import remarkSlug from 'remark-slug'
import styled from 'styled-components'
import { useRemoteConfigFlag } from '../hooks'
import { Colors } from '../styles'
import './Markdown.scss'

type Props = {
  children: string
  repository?: Mod['repository']
}

const StyledMarkdown = styled(BaseMarkdown)`
  line-height: 1.7;

  > *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    border-left: 4px solid ${Colors.LIGHT_GRAY3};
    color: ${Colors.GRAY2};
    margin-left: 0;
    padding: 0.5rem 0 0.5rem 1rem;

    p:last-child {
      margin-bottom: 0;
    }

    .${Classes.DARK} & {
      border-left: 4px solid ${Colors.DARK_GRAY3};
    }
  }

  code {
    padding: 4px;
    background-color: ${Colors.LIGHT_GRAY3};
    border-radius: 4px;

    .${Classes.DARK} & {
      background-color: ${Colors.DARK_GRAY3};
    }
  }

  pre > code {
    display: block;
    padding: 1rem;

    overflow-x: auto;
  }

  img {
    max-width: 100%;
  }
`

const rewriteImageSrc =
  (repository?: Mod['repository'], EXTERNAL_LINKS_NEW_WINDOW = false) =>
  (url: URL, node: any) => {
    // Open external links in new window?
    if (EXTERNAL_LINKS_NEW_WINDOW && node.tagName === 'a' && url.hostname) {
      node.properties.target = '_blank'
      return
    }

    if (node.tagName === 'a' && !url.pathname && url.hash) {
      // Add 'user-content-' to hash links
      url.hash = `#user-content-${url.hash.substr(1)}`
      return url
    }

    // Rewrite urls to repository content
    if (!repository) {
      return url
    }

    if (repository.provider !== RepositoryProvider.GitHub) {
      return url
    }

    if (!url.protocol) {
      if (node.tagName === 'a' && url.pathname) {
        url.protocol = 'https'
        url.hostname = 'github.com'
        url.pathname = `${repository.owner}/${repository.name}/blob/HEAD/${url.pathname}`
      } else if (node.tagName === 'img' && url.pathname) {
        url.protocol = 'https'
        url.hostname = 'raw.githubusercontent.com'
        url.pathname = `${repository.owner}/${repository.name}/HEAD/${url.pathname}`
      }
    }

    return url
  }

const remarkPlugins = [remarkEmoji, remarkGfm, remarkSlug]

// Allow className attribute in order for rehypeHighlight to pick up languages
const sanitizeSchema = { ...defaultSchema }
sanitizeSchema.attributes['*'].push('className')

const components: TransformOptions['components'] = {
  table({ node, ...props }) {
    return <HTMLTable bordered style={{ marginBottom: '1rem' }} {...props} />
  },
}

export function Markdown({ children, repository }: Props) {
  const EXTERNAL_LINKS_NEW_WINDOW = useRemoteConfigFlag(
    'external_links_new_window'
  )
  const rehypePlugins = useMemo(() => {
    return [
      rehypeRaw,
      [rehypeUrls, rewriteImageSrc(repository, EXTERNAL_LINKS_NEW_WINDOW)],
      [rehypeSanitize, sanitizeSchema],
      [rehypeHighlight, { ignoreMissing: true }] as any,
    ]
  }, [repository, EXTERNAL_LINKS_NEW_WINDOW])

  return (
    <StyledMarkdown
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins}
      components={components}
    >
      {children}
    </StyledMarkdown>
  )
}

export const PureMarkdown = React.memo(Markdown)
